/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts
import { Navigate } from "react-router-dom";

import Auth0VerifyEmailAddress from "views/pages/Auth0/Auth0VerifyEmailAddress/Auth0VerifyEmailAddress";
import AcceptUserCompanyInvite from "views/pages/Company/AcceptUserCompanyInvite/AcceptUserCompanyInvite";
import Dashboard from "views/pages/Dashboard";
import EmailVerification from "views/pages/EmailVerification/EmailVerification";
import Landing from "views/pages/Landing";
import Logout from "views/pages/Logout/Logout";
import SignIn from "views/pages/SignIn/SignIn";
import SignUp from "views/pages/SignUp/SignUp";
import SignUpSelectOrganizationType from "views/pages/SignUpFunnel/SignUpSelectOrganizationType/SignUpSelectOrganizationType";
import VerifyEmailAddress from "views/pages/VerifyEmailAddress/VerifyEmailAddress";

import AccountSettings from "layouts/pages/account/AccountSettings/AccountSettings";
import ClientConfig from "views/pages/Dashboard/ClientConfig";
import PendingAssetApprovals from "views/pages/Dashboard/PendingAssetApprovals";
import PolicyPacks from "views/pages/Dashboard/PolicyPacks";
import AdoptionCampaigns from "views/pages/Dashboard/AdoptionCampaigns";
import CompanyConfig from "views/pages/Dashboard/CompanyConfig";
import CompanyTemplates from "views/pages/Dashboard/CompanyTemplates";
import EditCompanyTemplate from "views/pages/Dashboard/CompanyTemplates/EditTemplate/EditTemplate";
import AddNewCustomer from "views/pages/Dashboard/Customers/AddNewCustomer/AddNewCustomer";
import CustomerManagement from "views/pages/Dashboard/Customers/CustomersManagement/CustomerManagement";
import EditConnectWiseClient from "views/pages/Dashboard/Customers/EditConnectWiseClient/EditConnectWiseClient";
import Policies from "views/pages/Dashboard/Policies";
import Templates from "views/pages/Dashboard/Templates";
import EditTemplate from "views/pages/Dashboard/Templates/EditTemplate/EditTemplate";
import AddNewAudience from "views/pages/Dashboard/Audience/AddNewAudience/AddNewAudience";
import AudienceManagement from "views/pages/Dashboard/Audience/AudienceManagement/AudienceManagement";
import ShowContactsByAudience from "views/pages/Dashboard/Audience/ShowContactsByAudience/ShowContactsByAudience";
import ListAuditLogs from "views/pages/Dashboard/AuditLogs/ListAuditLogs";
import ShowAuditLogs from "views/pages/Dashboard/AuditLogs/ShowAuditLogs";
import EditCompanyUser from "views/pages/Dashboard/Users/EditCompanyUser/EditCompanyUser";
import MSPUsersManagement from "views/pages/Dashboard/Users/MSPUsersManagement/MSPUsersManagement";
import EditMSPCompanyUser from "views/pages/Dashboard/Users/EditMSPCompanyUser/EditCompanyUser";
import ApiStatus from "views/pages/check-api";
import CreateNewAdoptionCampaign from "views/pages/Dashboard/AdoptionCampaigns/components/CreateNew";
import ViewAdoptionCampaign from "views/pages/Dashboard/AdoptionCampaigns/components/View";
import AssessmentTemplates from "views/pages/Dashboard/AssessmentDashboard/AssessmentTemplates";
import EditAssessmentTemplates from "views/pages/Dashboard/AssessmentDashboard/AssessmentTemplates/EditAssessmentTemplates";
import AssessmentEvents from "views/pages/Dashboard/AssessmentDashboard/AssessmentEvents";
import AssessmentEventVersions from "views/pages/Dashboard/AssessmentDashboard/AssessmentEventVersions";
import TakeTheAssessment from "views/pages/Dashboard/AssessmentDashboard/TakeAssessment";
import AssessmentReports from "views/pages/Dashboard/AssessmentDashboard/AssessmentReports";
import Ssp from "views/pages/Dashboard/AssessmentDashboard/Ssp";
import CreateNewSsp from "views/pages/Dashboard/AssessmentDashboard/Ssp/components/CreateNew";
import ViewSsp from "views/pages/Dashboard/AssessmentDashboard/Ssp/components/View";
import ViewSspVersions from "views/pages/Dashboard/AssessmentDashboard/Ssp/ccaVersions";
import AuditorReports from "views/pages/Dashboard/AuditorReports";
import CreateNewAuditorReport from "views/pages/Dashboard/AuditorReports/components/CreateNew";
import ViewAuditorReport from "views/pages/Dashboard/AuditorReports/components/View";
import AcceptAuditorInvite from "views/pages/Dashboard/AuditorReports/components/AcceptAuditorInvite";
import MSPClients from "views/pages/Dashboard/SuperAdmin/Clients";
import Companies from "views/pages/Dashboard/SuperAdmin/Companies";
import PlatformStats from "views/pages/Dashboard/SuperAdmin/PlatformStats";
import GlobalTemplates from "views/pages/Dashboard/SuperAdmin/GlobalTemplates";
import PolicySections from "views/pages/Dashboard/SuperAdmin/PolicySections";
import EditGlobalTemplate from "views/pages/Dashboard/SuperAdmin/GlobalTemplates/EditTemplate/EditTemplate";
import SuperAdminPasswordValidation from "views/pages/Dashboard/SuperAdmin/SuperAdminPasswordValidation/SuperAdminPasswordValidation";
import GlobalAssessmentTemplates from "views/pages/Dashboard/SuperAdmin/AssessmentTemplates";
import GlobalEditAssessmentTemplates from "views/pages/Dashboard/SuperAdmin/AssessmentTemplates/EditAssessmentTemplates";
import GlobalConfig from "views/pages/Dashboard/SuperAdmin/GlobalConfig";
import SiteSecurity from "views/pages/Dashboard/SuperAdmin/SiteSecurity";
import PeerGroupPosts from "views/pages/Dashboard/SuperAdmin/PeerGroupPosts";
import PeerGroupAdmins from "views/pages/Dashboard/SuperAdmin/PeerGroupAdmins";
import PeerGroupPostsDetails from "views/pages/Dashboard/PeerGroup/components/PeerGroupPostsDetails";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
// our icons
import settingIcon from "assets/images/icons/settings.svg";

// Images

import RedirectToDashboardIfLoggedInCheck from "Hoc/auth/RedirectToDashboardIfLoggedInCheck";
import Auth0CallBack from "views/pages/Auth0/Auth0CallBack/Auth0CallBack";
import SignUpCreateNewOrganization from "views/pages/SignUpFunnel/SignUpCreateNewOrganization/SignUpCreateNewOrganization";
import SignUpPickAPlan from "views/pages/SignUpFunnel/SignUpPickAPlan/SignUpPickAPlan";
import SignUpStripeCheckoutSuccess from "views/pages/SignUpFunnel/SignUpStripeCheckoutSuccess/SignUpStripeCheckoutSuccess";
import SignUpStripeSubscriptionCheckOutSuccess from "views/pages/SignUpFunnel/SignUpStripeSubscriptionCheckOutSuccess/SignUpStripeSubscriptionCheckOutSuccess";
import SignUpUserDetails from "views/pages/SignUpFunnel/SignUpUserDetails";
import AuthHoc from "./Hoc/auth/authHoc";
//
import AssetManagement from "views/pages/Dashboard/AssetManagement";
import CyberCNSAssetSyncSection from "views/pages/Dashboard/AssetManagement/AssetManagementClients/CyberCNSAssetSyncSection";
import ConnectSecurev4AssetSyncSection from "views/pages/Dashboard/AssetManagement/ConnectSecurev4AssetManagementClients/AssetSyncSection";

import NodewareAssetSyncSection from "views/pages/Dashboard/AssetManagement/NodewareAssetManagementClients/AssetSyncSection";
import LionguardAssetSyncSection from "views/pages/Dashboard/AssetManagement/LionguardAssetManagementClients/AssetSyncSection";
import {
  NinjaSoftwareAssetSyncSection,
  NinjaHardwareAssetSyncSection,
} from "views/pages/Dashboard/AssetManagement/NinjaAssetManagementClients/AssetSyncSection";
import NCentralAssetSyncSection from "views/pages/Dashboard/AssetManagement/NCentralAssetManagementClients/AssetSyncSection";
import {
  MSGraphAssetSyncSectionHardware,
  MSGraphAssetSyncSectionPeople,
} from "views/pages/Dashboard/AssetManagement/MSGraphAssetSyncSection";

import AssessmentConfig from "views/pages/Dashboard/AssessmentConfig";
import AssessmentTypes from "views/pages/Dashboard/AssessmentConfig/AssessmentTypes";
import AssessmentScoring from "views/pages/Dashboard/AssessmentConfig/AssessmentScoring";
import AssessmentItemScoring from "views/pages/Dashboard/AssessmentConfig/AssessmentItemScoring";

import PeerGroup from "views/pages/Dashboard/PeerGroup";

import SaveConnectWiseApiKeys from "views/pages/Dashboard/Connectwise/SaveConnectWiseApiKeys/SaveConnectWiseApiKeys";
import ConfigSymbol from "views/pages/Dashboard/Settings/Symbol/ConfigSymbol";
import ConfigHuntress from "views/pages/Dashboard/Settings/Huntress/ConfigHuntress";
import Settings from "views/pages/Dashboard/Settings";
import ApiKeysConfig from "views/pages/Dashboard/Settings/ApiKeysConfig";
import ConfigConnectSecure from "views/pages/Dashboard/Settings/ConnectSecure/ConfigConnectSecure";
import ConfigConnectSecurev4 from "views/pages/Dashboard/Settings/ConnectSecurev4/ConfigConnectSecure";
import {
  MsGraphEmailConnect,
  MsGraphAssetsConnect,
} from "views/pages/Dashboard/Settings/MsGraphEmailConnect";
import {
  ClientsToConfigureMsGraphEmailFor,
  ClientsToConfigureMsGraphAssetsFor,
} from "views/pages/Dashboard/Settings/MsGraphEmailConnect/components/ClientsToConfigureMsGraphFor";
import ConfigNodeware from "views/pages/Dashboard/Settings/Nodeware/ConfigNodeware";
import ConfigLionGuard from "views/pages/Dashboard/Settings/LionGuard/ConfigLionGuard";
import ConfigNinja from "views/pages/Dashboard/Settings/Ninja/ConfigNinja";
import ConfigNCentral from "views/pages/Dashboard/Settings/NCentral/ConfigNCentral";

import CreateNewWisp from "views/pages/Dashboard/WispScorecard/components/CreateNewWisp";
import ViewWisp from "views/pages/Dashboard/WispScorecard/components/ViewWisp";
import ViewWispVersions from "views/pages/Dashboard/WispScorecard/wispVersions";
import WispApprovals from "views/pages/Dashboard/WispScorecard/components/Approvals";

import ImportConnectWiseCustomers from "views/pages/Dashboard/Connectwise/ImportConnectWiseCustomers/ImportConnectWiseCustomers";
import CreateClientContactByClientId from "views/pages/Dashboard/Customers/CreateClientContactByClientId/CreateClientContactByClientId";
import MSGraphAutoContactsImportConfigSection from "views/pages/Dashboard/Customers/CreateClientContactByClientId/MSGraphAutoContactsImportConfigSection";
import EditClientContactByClientContactId from "views/pages/Dashboard/Customers/EditClientContactByClientContactId/EditClientContactByClientContactId";
import ShowClientContactsById from "views/pages/Dashboard/Customers/ShowClientContactsById/ShowClientContactsById";
import ImportFromMsGraph from "views/pages/Dashboard/Customers/importFromMsGraph";
import RetrieveTenantIdFromMsGraph from "views/pages/Dashboard/Settings/MsGraphEmailConnect/components/RetrieveTenantIdFromMsGraph";
import CreatePolicyDocument from "./views/pages/Dashboard/Policies/CreatePolicyDocument/CreatePolicyDocument";
import EditPolicy from "./views/pages/Dashboard/Policies/EditPolicy";
import PendingReviewsForUser from "./views/pages/Dashboard/Policies/PendingReviewsForUser";
import ReviewPolicyLanding from "./views/pages/Dashboard/Policies/ReviewPolicy/ReviewPolicyLanding";

import RedirectPrevious from "components/utils/RedirectPrevious";
import KnowledgeBaseShare from "views/pages/Dashboard/Customers/KnowledgeBaseShare";
import OpenedDocument from "views/pages/Dashboard/Customers/KnowledgeBaseShare/components/OpenedDocument";
import ImportFromConnectWise from "views/pages/Dashboard/Customers/importFromConnectWise";
import PolicyRedirect from "views/pages/Dashboard/Policies/components/PolicyRedirect";
import PolicyAdoptionRequest from "views/pages/Dashboard/PolicyAdoptionRequest";
import PoliciesAdoptionRequest from "views/pages/Dashboard/PoliciesAdoptionRequest";
import ForgotPasswordPage from "views/pages/ForgotPasswordPage";
import PricingPlansPage from "views/pages/PricingPlansPage";
import InsuranceScorecard from "views/pages/Dashboard/InsuranceScorecard";
import WispScorecard from "views/pages/Dashboard/WispScorecard";
import KnowledgeBase from "views/pages/Dashboard/KnowledgeBase";
import FifthWallInfo from "views/pages/Dashboard/SuperAdmin/FifthWallInfo";
import AssetConnections from "views/pages/Dashboard/AssetsConnections";
import AssetLists from "views/pages/Dashboard/AssetLists";
import RiskRegister from "views/pages/Dashboard/RiskRegister";
import RiskRegisterLists from "views/pages/Dashboard/RiskRegisterLists";
import PlanOfActionandMilestones from "views/pages/Dashboard/Poam";
import PublicLinkPasswordValidation from "views/pages/PublicLinkPasswordValidation/PublicLinkPasswordValidation";
import EvidenceLocker from "views/pages/Dashboard/EvidenceLocker";
import { MsGraphOneDriveConnect } from "views/pages/Dashboard/EvidenceLocker/MsGraphOneDriveConnect";
import RetrieveTenantIdFromMsGraphOneDrive from "views/pages/Dashboard/EvidenceLocker/MsGraphOneDriveConnect/components/RetrieveTenantIdFromMsGraph";
import { MsGraphSharePointConnect } from "views/pages/Dashboard/EvidenceLocker/MsGraphSharePointConnect";
import RetrieveTenantIdFromMsGraphSharePoint from "views/pages/Dashboard/EvidenceLocker/MsGraphSharePointConnect/RetrieveTenantIdFromMsGraph";
import ConfigSharePointSiteId from "views/pages/Dashboard/EvidenceLocker/MsGraphSharePointConnect/ConfigSharePointSiteId";
import { MsGraphSharePointGccHighConnect } from "views/pages/Dashboard/EvidenceLocker/MsGraphSharePointGccHighConnect";
import RetrieveTenantIdFromMsGraphSharePointGcc from "views/pages/Dashboard/EvidenceLocker/MsGraphSharePointGccHighConnect/RetrieveTenantIdFromMsGraph";
import ConfigSharePointSiteGccId from "views/pages/Dashboard/EvidenceLocker/MsGraphSharePointGccHighConnect/ConfigSharePointSiteId";
import TrustCenter from "views/pages/Dashboard/TrustCenter";
// import NotificationCenter from "views/pages/Dashboard/NotificationCenter";
import { MSTeamsConnect } from "views/pages/Dashboard/NotificationCenter/MsGraphTeamsConnect";
import RetrieveTenantIdFromMsGraphMSTeams from "views/pages/Dashboard/NotificationCenter/MsGraphTeamsConnect/RetrieveTenantIdFromMsGraph";
import SuperAdminConfigurables from "views/pages/Dashboard/SuperAdmin/SuperAdminConfigurables";
import { SlackConnect } from "views/pages/Dashboard/NotificationCenter/SlackConnect";
import RetrieveTenantIdFromSlack from "views/pages/Dashboard/NotificationCenter/SlackConnect/RetrieveTenantIdFromSlack";
import PublicTrustCenter from "views/pages/Dashboard/TrustCenter/PublicTrustCenter";
import SuperAdminToolKit from "views/pages/Dashboard/SuperAdmin/SuperAdminToolKit";
import AssessmentEventFix from "views/pages/Dashboard/SuperAdmin/SuperAdminToolKit/AssessmentEventFix";
import TwoFAFix from "views/pages/Dashboard/SuperAdmin/SuperAdminToolKit/2FAFix";
import CancelAccountFix from "views/pages/Dashboard/SuperAdmin/SuperAdminToolKit/CancelAccountFix";
import ReassignToCompany from "views/pages/Dashboard/SuperAdmin/SuperAdminToolKit/ReassignToCompany";
import ImportTemplateFromStaging from "views/pages/Dashboard/SuperAdmin/SuperAdminToolKit/ImportTemplateFromStaging";
import TaskList from "views/pages/Dashboard/TaskList";
import SecurityAwarenessTraining from "views/pages/Dashboard/SecurityAwarenessTraining";
import GlobalTaskListTemplates from "views/pages/Dashboard/SuperAdmin/TaskListTemplates";
import ContractStats from "views/pages/Dashboard/SuperAdmin/ContractStats";
import TaskListReports from "views/pages/Dashboard/TaskList/Reports";
import MSPTaskListTemplates from "views/pages/Dashboard/TaskList/Templates";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    cannotBeAccessedBy: ["auditor"],
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={Dashboard}
        page_name={"dashboard"}
      />
    ),
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
    private: true,
  },

  {
    type: "collapse",
    name: "Assessment Scorecard",
    key: "assessment",
    route: "/assessment",
    icon: <Icon fontSize="medium">quiz</Icon>,
    clientPermissionRequired: ["assessmentScorecardAccess"],
    mustBeCompanyAdminOrCompanyUserorAssessmentClient: true,
    collapse: [
      {
        name: "Assessment Events",
        key: "events",
        route: "/assessments/events",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AssessmentEvents}
            page_name={"AssessmentEvents"}
            isCheckCompanyOrganization={true}
          />
        ),
        noCollapse: true,
        private: true,
      },
      {
        name: "Assessment Templates",
        key: "templates",
        route: "/assessments/templates",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AssessmentTemplates}
            page_name={"AssessmentTemplates"}
            isCheckCompanyOrganization={true}
          />
        ),
        noCollapse: true,
        private: true,
      },
      {
        name: "Assessment Reports",
        key: "reports",
        route: "/assessments/reports",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AssessmentReports}
            page_name={"AssessmentReports"}
            isCheckCompanyOrganization={true}
          />
        ),
        noCollapse: true,
        private: true,
      },
      {
        name: "Assessment Config",
        key: "config",
        route: "/assessments/config",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AssessmentConfig}
            page_name={"AssessmentConfig"}
            isCheckCompanyOrganization={true}
          />
        ),
        noCollapse: true,
        private: true,
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "Compliance Control",
        key: "cca",
        route: "/assessments/CCA",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={Ssp}
            page_name={"Ssp"}
            isCheckCompanyOrganization={true}
          />
        ),
        noCollapse: true,
        private: true,
      },
    ],
  },

  {
    type: "collapse",
    name: "Asset Scorecard",
    key: "asset_scorecard",
    route: "/asset_scorecard",
    icon: <Icon fontSize="medium">laptop</Icon>,
    cannotBeAccessedBy: ["auditor"],
    clientPermissionRequired: ["assetScorecardAccess"],
    collapse: [
      {
        name: "Asset Lists",
        key: "assets",
        route: "/assets",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AssetLists}
            page_name={"AssetManagement"}
            isCheckCompanyOrganization={true}
          />
        ),
        noCollapse: true,
        private: true,
      },
      {
        name: "Asset Connections",
        key: "connections",
        route: "assets/connections",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AssetConnections}
            page_name={"AssetConnections"}
            isCheckCompanyOrganization={true}
          />
        ),
        noCollapse: true,
        private: true,
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "Asset Approvals",
        key: "approvals",
        route: "assets/approvals",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PendingAssetApprovals}
            page_name={"AssetConnections"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
    ],
  },

  {
    type: "collapse",
    name: "Policy Scorecard",
    key: "policy_scorecard",
    route: "/policy_scorecard",
    icon: <Icon fontSize="medium">article</Icon>,
    cannotBeAccessedBy: ["auditor"],
    clientPermissionRequired: ["policyScorecardAccess"],
    collapse: [
      {
        name: "Our Policies",
        key: "policies",
        route: "/policies",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={Policies}
            page_name={"Policies"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Pending Reviews",
        key: "pending-reviews",
        route: "/pending-reviews",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PendingReviewsForUser}
            page_name={"PendingReviewsForUser"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Adoption Campaigns",
        key: "adoption-campaigns",
        route: "/adoption-campaigns",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AdoptionCampaigns}
            page_name={"AdoptionCampaigns"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Template Library",
        key: "template-library",
        route: "/template-library",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={CompanyTemplates}
            page_name={"CompanyTemplates"}
            isCheckCompanyOrganization={true}
            clientPermissionRequired={["hasPolicyTemplateAccess"]}
          />
        ),
        clientPermissionRequired: ["hasPolicyTemplateAccess"],
      },
      {
        name: "Policy Packs",
        key: "policy-packs",
        route: "/policy-packs",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PolicyPacks}
            page_name={"PolicyPacks"}
          />
        ),
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "Knowledge Base",
        key: "knowledge-base",
        route: "/knowledge-base",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={KnowledgeBase}
            page_name={"KnowledgeBase"}
          />
        ),
      },
    ],
  },

  {
    type: "collapse",
    name: "WISP Scorecard",
    key: "wisp",
    route: "/wisp",
    icon: <Icon fontSize="medium">drive_file_rename_outline</Icon>,
    cannotBeAccessedBy: ["auditor"],
    clientPermissionRequired: ["wispScorecardAccess"],
    collapse: [
      {
        name: "Generated WISPs",
        key: "wisp",
        route: "/wisp",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={WispScorecard}
            page_name={"WispScorecard"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "WISP Approvals",
        key: "wisp-approvals",
        route: "/wisp/approvals",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={WispApprovals}
            page_name={"WispApprovals"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
    ],
  },

  {
    type: "collapse",
    name: "Insurance Scorecard",
    key: "insurance",
    route: "/insurance",
    icon: <Icon fontSize="medium">security</Icon>,
    cannotBeAccessedBy: ["auditor"],
    clientPermissionRequired: ["insuranceScorecardAccess"],
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={InsuranceScorecard}
        page_name={"InsuranceScorecard"}
        isCheckCompanyOrganization={true}
      />
    ),
    noCollapse: true,
    private: true,
    mustBeCompanyAdminOrCompanyUser: true,
  },
  {
    type: "collapse",
    name: "SAT Scorecard",
    key: "sat",
    route: "/sat",
    icon: <Icon fontSize="medium">hardware</Icon>,
    cannotBeAccessedBy: ["auditor"],
    // clientPermissionRequired: [""],
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={SecurityAwarenessTraining}
        page_name={"SecurityAwarenessTraining"}
        isCheckCompanyOrganization={true}
      />
    ),
    noCollapse: true,
    private: true,
    mustBeCompanyAdminOrCompanyUser: true,
  },

  {
    type: "collapse",
    name: "Auditor Reports",
    key: "auditor-reports",
    route: "/auditor-reports",
    icon: <Icon fontSize="medium">auto_stories</Icon>,
    clientPermissionRequired: ["auditorReportAccess"],
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AuditorReports}
        page_name={"Auditor Reports"}
      />
    ),
    noCollapse: true,
    private: true,
  },

  {
    type: "collapse",
    name: "Risk Scorecard",
    key: "lock",
    route: "/risk-register",
    mustBeCompanyAdminOrCompanyUserorRiskRegisterClient: true,
    icon: <Icon fontSize="medium">lock</Icon>,
    clientPermissionRequired: ["riskRegisterAccess"],
    collapse: [
      {
        name: "Risk Findings",
        key: "risk-findings",
        route: "/risk-findings",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={RiskRegister}
            page_name={"RiskRegister"}
          />
        ),
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "Risk Register",
        key: "risk-register",
        route: "/risk-register",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={RiskRegisterLists}
            page_name={"RiskRegisterLists"}
          />
        ),
        mustBeCompanyAdminOrCompanyUserorRiskRegisterClient: true,
      },

      {
        name: "POAM",
        key: "plan-of-action-&-milestones",
        route: "/plan-of-action-&-milestones",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PlanOfActionandMilestones}
            page_name={"Settings"}
            isCheckCompanyOrganization={true}
          />
        ),
        mustBeCompanyAdminOrCompanyUserorRiskRegisterClient: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Peer Group",
    key: "peer-group",
    route: "/peer-group",
    icon: <Icon fontSize="medium">accessibility</Icon>,
    cannotBeAccessedBy: ["auditor"],
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={PeerGroup}
        page_name={"PeerGroup"}
        isCheckCompanyOrganization={true}
      />
    ),
    noCollapse: true,
    private: true,
    mustBeCompanyAdminOrCompanyUser: true,
  },
  {
    type: "collapse",
    name: "Trust Center",
    key: "trust-center",
    route: "/trust-center",
    icon: <Icon fontSize="medium">verified</Icon>,
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={TrustCenter}
        page_name={"TrustCenter"}
        isCheckCompanyOrganization={true}
      />
    ),
    noCollapse: true,
    private: true,
    mustBeCompanyAdminOrCompanyUser: true,
  },

  // Task Lists
  {
    type: "collapse",
    name: "Projects Center (Beta)",
    key: "project-center",
    route: "/project-center",
    icon: <Icon fontSize="medium">task</Icon>,
    collapse: [
      {
        name: "Projects",
        key: "projects",
        route: "/projects",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={TaskList}
            page_name={"TaskList"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Projects Templates",
        key: "project-templates",
        route: "/project-templates",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={MSPTaskListTemplates}
            page_name={"MSPTaskListTemplates"}
            isCheckCompanyOrganization={true}
          />
        ),
        clientPermissionRequired: ["projectTemplatesAccess"],
      },
      {
        name: "Projects Reports",
        key: "project-reports",
        route: "/projects-reports",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={TaskListReports}
            page_name={"TaskListReports"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
    ],
  },
  {
    type: "collapse",
    name: "Post Config",
    key: "post-config",
    route: "/post-config",
    icon: (
      <MDAvatar
        src={settingIcon}
        alt="buildingIcon"
        sx={{ width: "1.5rem" }}
        variant="square"
      />
    ),
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={PeerGroupPosts}
        page_name={"PeerGroupPosts"}
        isCheckCompanyOrganization={true}
      />
    ),
    noCollapse: true,
    private: true,
    mustBeCompanyAdminOrCompanyUser: true,
    mustBePeerGroupAdmin: true,
  },
  /**super admin menu */
  {
    type: "collapse",
    name: "Super Admin",
    key: "superadmin",
    route: "/superadmin",
    icon: <Icon fontSize="medium">stream</Icon>,
    mustBeSuperAdmin: true,
    collapse: [
      {
        type: "collapse",
        name: "Platform Stats",
        key: "platform-stats",
        route: "/super-admin/platform-stats",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PlatformStats}
            page_name={"PlatformStats"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "MSP Stats",
        key: "msp",
        route: "/super-admin/msp",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={Companies}
            page_name={"Companies"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Contract Stats",
        key: "contact",
        route: "/super-admin/contracts",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ContractStats}
            page_name={"ContractStats"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "FifthWall Stats",
        key: "fifthwall-stats",
        route: "/super-admin/fifthwall-stats",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={FifthWallInfo}
            page_name={"FifthWallStats"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Global Template Library",
        key: "global-templates",
        route: "/super-admin/global-templates",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={GlobalTemplates}
            page_name={"GlobalTemplates"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Policy Sections",
        key: "policy-sections",
        route: "/super-admin/policy-sections",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PolicySections}
            page_name={"PolicySections"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Assessment Templates",
        key: "global-assessment-templates",
        route: "/super-admin/global-assessment-templates",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={GlobalAssessmentTemplates}
            page_name={"GlobalAssessmentTemplates"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Projects Templates (Beta)",
        key: "global-projects-templates",
        route: "/super-admin/global-projects-templates",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={GlobalTaskListTemplates}
            page_name={"GlobalTaskListTemplates"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Policy Packs",
        key: "config",
        route: "/super-admin/config",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={GlobalConfig}
            page_name={"GlobalConfig"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Peer Group Posts",
        key: "peer-group-posts",
        route: "/super-admin/peer-group-posts",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PeerGroupPosts}
            page_name={"PeerGroupPosts"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Peer Group Admins",
        key: "peer-group-admins",
        route: "/super-admin/peer-group-admins",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PeerGroupAdmins}
            page_name={"PeerGroupAdmins"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Site Security",
        key: "site-security",
        route: "/super-admin/site-security",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SiteSecurity}
            page_name={"SiteSecurity"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Configurables",
        key: "configurables",
        route: "/super-admin/configurables",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SuperAdminConfigurables}
            page_name={"SuperAdminConfigurables"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Toolkit",
        key: "toolkit",
        route: "/super-admin/toolkit",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SuperAdminToolKit}
            page_name={"SuperAdminToolKit"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
    ],
  },
];

const topNavRoutes = [
  {
    type: "collapse",
    name: "My Account",
    key: "user-account-settings",
    route: "/user-account-settings",
    icon: <Icon fontSize="medium">account_circle</Icon>,
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AccountSettings}
        page_name={"AccountSettings"}
      />
    ),
    noCollapse: true,
    private: true,
    mustBeClientUser: true,
  },
  {
    type: "collapse",
    name: "Company Config",
    key: "company_config",
    route: "/company_config",
    icon: <Icon fontSize="medium">person</Icon>,
    cannotBeAccessedBy: ["auditor"],
    clientPermissionRequired: [
      "hasCompanyAudienceAccess",
      "hasCompanyContactsAccess",
      "hasCompanySettingsAccess",
      "hasEvidenceLockerAccess",
    ],
    collapse: [
      {
        name: "Audiences",
        key: "audiences",
        route: "/audiences",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AudienceManagement}
            page_name={"AudienceManagement"}
            isCheckCompanyOrganization={true}
            clientPermissionRequired={["hasCompanyAudienceAccess"]}
          />
        ),
      },
      {
        name: "Contacts",
        key: "contacts",
        route: "/contacts",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ShowClientContactsById}
            page_name={"ShowClientContactsById"}
            isCheckCompanyOrganization={true}
            clientPermissionRequired={["hasCompanyContactsAccess"]}
          />
        ),
      },
      {
        name: "Company Settings",
        key: "company-settings",
        route: "/company-settings",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ClientConfig}
            page_name={"ClientConfig"}
            clientPermissionRequired={["hasCompanySettingsAccess"]}
          />
        ),
      },
      {
        name: "Evidence Locker",
        key: "evidence-locker",
        route: "/evidence-locker",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EvidenceLocker}
            page_name={"EvidenceLocker"}
            clientPermissionRequired={["hasEvidenceLockerAccess"]}
          />
        ),
      },
      // {
      //   name: "Notification Center",
      //   key: "notification-center",
      //   route: "/notification-center",
      //   component: (
      //     <AuthHoc
      //       checkAuth={true}
      //       isRedirect={true}
      //       Component={NotificationCenter}
      //       page_name={"NotificationCenter"}
      //     />
      //   ),
      // },
    ],
  },
  {
    type: "collapse",
    name: "MSP Global Settings",
    key: "setting",
    route: "/users",
    canBeAccessedBy: ["admin", "company_user"],
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Account Details",
        key: "account-details",
        route: "/account-details",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AccountSettings}
            page_name={"AccountSettings"}
          />
        ),
      },
      {
        name: "Clients ",
        key: "clients",
        route: "/clients",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={CustomerManagement}
            page_name={"CustomerManagement"}
            isCheckCompanyOrganization={true}
          />
        ),
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "MSP Users",
        key: "msp-users",
        route: "/msp-users",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={MSPUsersManagement}
            page_name={"MSPUsersManagement"}
            isCheckCompanyOrganization={true}
          />
        ),
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "Audit logs",
        key: "audit-logs",
        route: "/audit-logs",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ListAuditLogs}
            page_name={"ListAuditLogs"}
          />
        ),
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "Connections",
        key: "msp-connections",
        route: "/msp-connections",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={Settings}
            page_name={"Settings"}
            isCheckCompanyOrganization={true}
          />
        ),
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "MSP Config",
        key: "msp-config",
        route: "/msp-config",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={CompanyConfig}
            page_name={"MSPConfig"}
            isCheckCompanyOrganization={true}
          />
        ),
        mustBeCompanyAdminOrCompanyUser: true,
      },
      {
        name: "Billing",
        key: "billing",
        route: "/billing",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SignUpPickAPlan}
            page_name={"SignUpPickAPlan"}
            isCheckCompanyOrganization={true}
            redirectToBillingIfNotSubscribedTheFirstTime={false}
          />
        ),
        mustBeCompanyAdminOrCompanyUser: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    route: "/support",
    icon: <Icon fontSize="medium">help_center</Icon>,
    collapse: [
      {
        type: "collapse",
        name: "Help Desk",
        key: "Help Desk",
        route: "https://support.compliancescorecard.com",
        noCollapse: true,
        private: true,
      },
      {
        type: "collapse",
        name: "Onboarding Check List",
        key: "Onboarding Check list",
        route:
          "https://compliancerisk.atlassian.net/wiki/spaces/CRIODOCS/pages/148471813/Onboarding+Checklist",
        noCollapse: true,
        private: true,
      },
      {
        type: "collapse",
        name: "Doc Center",
        key: "Doc Center",
        route: "https://docs.compliancescorecard.com",
        noCollapse: true,
        private: true,
      },
      {
        type: "collapse",
        name: "Release Notes",
        key: "Release Notes",
        route: "https://releasenotes.compliancescorecard.com",
        noCollapse: true,
        private: true,
      },
      {
        type: "collapse",
        name: "Suggest a Feature",
        key: "Suggest a Feature",
        route: "https://featurerequests.compliancescorecard.com",
        noCollapse: true,
        private: true,
      },
    ],
  },
];

const publicRoutes = [
  // Redirect routes (to make sure breadcrumb works)
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    route: "/logout",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={Logout}
        page_name={"logout"}
      />
    ),
    icon: <Icon fontSize="medium">logout</Icon>,
    noCollapse: true,
    private: true,
  },
  {
    key: "user-edit-redirect",
    route: "/users/edit-user",
    component: <Navigate to="/users" replace={true} />,
  },
  {
    key: "home-page-redirect",
    route: "/home",
    component: <Navigate to="/dashboard" replace={true} />,
  },
  {
    key: "asset-management-redirect",
    route: "/asset-management",
    component: <Navigate to="/assets" replace={true} />,
  },
  {
    key: "settings-redirect",
    route: "/settings",
    component: <Navigate to="/settings/account-settings" replace={true} />,
  },
  {
    key: "assessments-redirect",
    route: "/assessments/",
    component: <Navigate to="/assessments/events" replace={true} />,
  },
  {
    key: "assessments-cca-redirect",
    route: "/assessments/CCA/view",
    component: <Navigate to="/assessments/CCA" replace={true} />,
  },
  {
    key: "wisp-redirect",
    route: "/wisp/view",
    component: <Navigate to="/wisp" replace={true} />,
  },
  {
    key: "ssp-redirect",
    route: "/cca/view",
    component: <Navigate to="/assessments/CCA" replace={true} />,
  },
  {
    key: "settings-main-redirect",
    route: " /settings/main",
    component: <Navigate to="/connections" replace={true} />,
  },
  {
    key: "setup-account-redirect",
    route: "/setup-account",
    component: <Navigate to="/setup-account/step-one" replace={true} />,
  },
  {
    key: "msteams-redirect",
    route: "/notification-center/msteams",
    component: <Navigate to="/notification-center" replace={true} />,
  },
  {
    key: "slack-redirect",
    route: "/notification-center/slack",
    component: <Navigate to="/notification-center" replace={true} />,
  },
  {
    key: "policies-review-redirect",
    route: "/clients/:clientId/policies/:policyId/review",
    component: <RedirectPrevious />,
  },
  {
    key: "policies-version-redirect",
    route: "/clients/:clientId/policies/:policyId/version",
    component: <RedirectPrevious />,
  },
  {
    key: "asset-lists",
    route: "/asset-lists",
    component: <Navigate to="/assets" replace={true} />,
  },
  {
    key: "global-assessment-templates",
    route: "/super-admin/assessment-templates",
    component: (
      <Navigate to="/super-admin/global-assessment-templates" replace={true} />
    ),
  },
  {
    name: "MSP Clients",
    key: "msp-clients",
    route: "/msp/clients",
    component: <RedirectPrevious />,
  },

  // Regular routes
  {
    type: "collapse",
    name: "Dashboard Pages",
    key: "dashboard",
    collapse: [
      {
        name: "Add New Client",
        key: "add-new-client",
        route: "/clients/add-new-client",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AddNewCustomer}
            page_name={"AddNewCustomer"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
    ],
  },
  {
    name: "Landing",
    key: "landing",
    route: "/",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={false}
        Component={Landing}
        page_name={"Landing"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    type: "collapse",
    name: "Api Status",
    key: "ApiStauts",
    collapse: [
      {
        name: "Api Status",
        key: "ApiStauts",
        route: "/check/api",
        component: <ApiStatus />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Auth",
    key: "auth",
    collapse: [
      {
        name: "SignIn",
        key: "sign-in",
        route: "/sign-in",
        component: (
          <RedirectToDashboardIfLoggedInCheck
            checkAuth={true}
            Component={SignIn}
            page_name={"signin"}
          />
        ),
      },
      {
        name: "SignUp",
        key: "sign-up",
        route: "/sign-up",

        component: (
          <RedirectToDashboardIfLoggedInCheck
            checkAuth={true}
            Component={SignUp}
            page_name={"signup"}
          />
        ),
      },
      {
        name: "stripeCheckoutSuccess",
        key: "stripe-checkout-success",
        route: "/stripe-checkout/success",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SignUpStripeCheckoutSuccess}
            page_name={"stripecheckoutsuccess"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "EmailVerification",
        key: "email-verification",
        route: "/email-verification",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EmailVerification}
            page_name={"emailverification"}
          />
        ),
      },
      {
        name: "VerifyEmail",
        key: "verify-email",
        route: "/verify-email/:verification_token",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={VerifyEmailAddress}
            isCheckEmailVerification={false}
            page_name={"verifyemailaddress"}
          />
        ),
      },
      {
        name: "Auth0VerifyEmail",
        key: "auth0-verify-email",
        route: "/auth0-verify-email",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={Auth0VerifyEmailAddress}
            isCheckEmailVerification={false}
            page_name={"Auth0VerifyEmailAddress"}
          />
        ),
      },
      {
        name: "signUpSelectOrganizationType",
        key: "setup-account-step-one",
        route: "/setup-account/step-one",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SignUpSelectOrganizationType}
            page_name={"signupselectorganizationtype"}
          />
        ),
      },
      {
        name: "signUpUserDetails",
        key: "setup-account-step-two",
        route: "/setup-account/step-two",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SignUpUserDetails}
            page_name={"SignUpUserDetails"}
          />
        ),
      },
      {
        name: "signUpCreateOrganization",
        key: "setup-account-step-three",
        route: "/setup-account/step-three",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SignUpCreateNewOrganization}
            page_name={"signupcreateneworganization"}
          />
        ),
      },
      {
        name: "signUpSignUpStripeSubscriptionCheckoutSuccess",
        key: "sign-up-SignUpStripeSubscriptionCheckoutSuccess",
        route: "/sign-up/stripe-subscription-checkout-success",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={SignUpStripeSubscriptionCheckOutSuccess}
            page_name={"SignUpStripeSubscriptionCheckoutSuccess"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "auth0CallBack",
        key: "auth-0-callback",
        route: "/auth0/callback",
        component: <Auth0CallBack />,
      },
      {
        name: "VerifyEmail",
        key: "accept-invite",
        route: "/accept-invite/:invite_token",
        component: (
          <RedirectToDashboardIfLoggedInCheck
            checkAuth={true}
            Component={AcceptUserCompanyInvite}
            page_name={"acceptinvite"}
          />
        ),
      },
      {
        name: "ReviewPolicyAdoptionRequest",
        key: "review-policy-adoption-request",
        route:
          "/policy-adoption/:company_id/:client_id/:contact_id/:campaign_id/:adoption_secret",
        component: <PolicyAdoptionRequest />,
      },
      {
        name: "ReviewsPoliciesAdoptionRequest",
        key: "review-policies-adoption-request",
        route:
          "/policies-adoption/:company_id/:client_id/:contact_id/:grouped_campaign_id",
        component: <PoliciesAdoptionRequest />,
      },
      {
        name: "KnowledgeBase",
        key: "kbs",
        route: "/kbs/:company_id/:client_id/:kbs_id/:kbs_secret",
        component: <KnowledgeBaseShare />,
      },
      {
        name: "KnowledgeBase",
        key: "kbs",
        route:
          "/kbs/open-document/:company_id/:client_id/:kbs_id/:kbs_secret/:policy_id/:policy_document_id",
        component: <OpenedDocument />,
      },
      {
        name: "Trust Center",
        key: "trust-center",
        route: "/:client_id/trust-center",
        component: <PublicTrustCenter />,
      },
      {
        name: "Pricing Page",
        key: "pricing",
        route: "/pricing-plans",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={false}
            Component={PricingPlansPage}
            page_name={"Pricing Page"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "EditCompanyUser",
        key: "edit-user",
        route: "/users/edit-user/:id",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditCompanyUser}
            isCheckEmailVerification={false}
            page_name={"EditCompanyUser"}
          />
        ),
      },
      {
        name: "EditMSPUser",
        key: "edit-msp-user",
        route: "/msp-users/edit-user/:id",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditMSPCompanyUser}
            isCheckEmailVerification={false}
            page_name={"EditMSPCompanyUser"}
          />
        ),
      },
      {
        name: "Forgot Password Page",
        key: "forgotpasswordpage",
        route: "/forgot-password",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={false}
            Component={ForgotPasswordPage}
            page_name={"ForgotPasswordPage"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Audit logs",
        key: "audit-logs-details",
        route: "/settings/audit-logs/:id",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ShowAuditLogs}
            page_name={"ShowAuditLogs"}
          />
        ),
      },
      {
        name: "Clients Management Edit Client",
        key: "clients-edit-client",
        route: "/clients/:id",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={PolicyRedirect}
            page_name={"PolicyRedirect"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Clients Management Edit Client",
        key: "clients-edit-client",
        route: "/clients/:id/edit",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditConnectWiseClient}
            page_name={"EditConnectWiseClient"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Client Create Contacts",
        key: "clients-management-create-client-contacts",
        route: "/contacts/create",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={CreateClientContactByClientId}
            page_name={"CreateClientContactByClientId"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Client Config MS Graph Auto Contacts Import",
        key: "clients-config-ms-graph-auto-contacts-import",
        route: "/contacts/ms-graph-contacts-auto-import-config",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={MSGraphAutoContactsImportConfigSection}
            page_name={"MSGraphAutoContactsImportConfigSection"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Client Contacts",
        key: "clients-management-client-contact",
        route: "/contacts/:client_contact_id",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditClientContactByClientContactId}
            page_name={"EditClientContactByClientContactId"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "MS Graph Auth",
        key: "ms-graph-auth",
        route: "/graph/auth",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ImportFromMsGraph}
            page_name={"ImportFromMsGraph"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "MS Graph Email Connect Auth",
        key: "ms-graph-email-connect-auth",
        route: "/settings/graph/email/auth",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={RetrieveTenantIdFromMsGraph}
            page_name={"RetrieveTenantIdFromMsGraph"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "MS Graph OneDrive Connect Auth",
        key: "ms-graph-onedrive-connect-auth",
        route: "evidence-locker/onedrive/auth",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={RetrieveTenantIdFromMsGraphOneDrive}
            page_name={"RetrieveTenantIdFromMsGraphOneDrive"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "MS Graph SharePoint Connect Auth",
        key: "ms-graph-sharepoint-connect-auth",
        route: "evidence-locker/sharepoint/auth",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={RetrieveTenantIdFromMsGraphSharePoint}
            page_name={"RetrieveTenantIdFromMsGraphSharePoint"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      // {
      //   name: "MS Graph GCC HIGH SharePoint Connect Auth",
      //   key: "ms-graph-gcc-high-sharepoint-connect-auth",
      //   route: "evidence-locker/sharepoint-gcc/auth",
      //   component: (
      //     <AuthHoc
      //       checkAuth={true}
      //       isRedirect={true}
      //       Component={RetrieveTenantIdFromMsGraphSharePointGcc}
      //       page_name={"RetrieveTenantIdFromMsGraphSharePointGcc"}
      //       isCheckCompanyOrganization={true}
      //     />
      //   ),
      // },
      {
        name: "MS Teams Connect Auth",
        key: "ms-teams-connect-auth",
        route: "notification-center/msteams/auth",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={RetrieveTenantIdFromMsGraphMSTeams}
            page_name={"RetrieveTenantIdFromMsGraphMSTeams"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Slack Connect Auth",
        key: "slack-connect-auth",
        route: "notification-center/slack/auth",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={RetrieveTenantIdFromSlack}
            page_name={"RetrieveTenantIdFromSlack"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "ConnectWise Import",
        key: "connectwise-import",
        route: "/clients/import-from-connectwise",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ImportFromConnectWise}
            page_name={"importFromConnectWise"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Company Settings",
        key: "company-settings",
        route: "/company-settings",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ClientConfig}
            page_name={"ClientConfig"}
            clientPermissionRequired={["hasCompanySettingsAccess"]}
          />
        ),
      },
      {
        name: "Audience - Create",
        key: "audiences-create",
        route: "/audiences/create",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AddNewAudience}
            page_name={"AddNewAudience"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        name: "Audience - List contacts",
        key: "audience-show-contact",
        route: "/audiences/:audience_id",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ShowContactsByAudience}
            page_name={"ShowContactsByAudience"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        name: "ImportConnectWiseCustomers",
        key: "import-connectwise-customers",
        route: "/connectwise/import-connectwise-customers",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ImportConnectWiseCustomers}
            page_name={"ImportconnectwiseCustomers"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      /** Templates*/
      {
        name: "Client Templates",
        key: "templates",
        route: "/clients/:clientId/templates",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={Templates}
            page_name={"Templates"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        name: "Client Template - Wizard",
        key: "client-template-wizard",
        route: "/clients/:clientId/templates/:templateId", // component: <PoliciesCreate />,
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditTemplate}
            page_name={"Edit Template"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        name: "Company Template - Wizard",
        key: "company-template-wizard",
        route: "/template-library/:templateId",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditCompanyTemplate}
            page_name={"Edit Company Template"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        name: "Global Template - Wizard",
        key: "global-template-wizard",
        route: "/super-admin/templates/:templateId",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditGlobalTemplate}
            page_name={"Edit Global Template"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        type: "collapse",
        name: "Edit Assessment Templates",
        key: "global-assessment-templates-edit",
        route: "/super-admin/assessment-templates/:template_id",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={GlobalEditAssessmentTemplates}
            page_name={"GlobalEditAssessmentTemplates"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Toolkit",
        key: "toolkit",
        route: "/super-admin/toolkit/assessment-event-fix",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={AssessmentEventFix}
            page_name={"AssessmentEventFix"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Toolkit",
        key: "toolkit",
        route: "/super-admin/toolkit/2fa-fix",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={TwoFAFix}
            page_name={"TwoFAFix"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Toolkit",
        key: "toolkit",
        route: "/super-admin/toolkit/cancel-account-fix",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={CancelAccountFix}
            page_name={"CancelAccountFix"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Toolkit",
        key: "toolkit",
        route: "/super-admin/toolkit/reassign-user-to-company",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ReassignToCompany}
            page_name={"ReassignToCompany"}
            isCheckCompanyOrganization={true}
          />
        ),
      },
      {
        type: "collapse",
        name: "Toolkit",
        key: "toolkit",
        route: "/super-admin/toolkit/import-template-from-staging",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ImportTemplateFromStaging}
            page_name={"ReassignToCompany"}
            isCheckCompanyOrganization={true}
          />
        ),
      },

      /** */
      {
        name: "Policy Review",
        key: "policies-review",
        route: "/policies/:policyId/review/:policyReviewId",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ReviewPolicyLanding}
            page_name={"Policy Review"}
          />
        ),
      },
      {
        name: "Policies Version - Wizard",
        key: "policies-version-wizard",
        route: "/policies/:policyId/:version",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={CreatePolicyDocument}
            page_name={"CreatePolicyDocument"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        name: "Policies - Create",
        key: "policies-create",
        route: "/policies/:policyId",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditPolicy}
            page_name={"EditPolicy"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        name: "Asset Lists - Create",
        key: "assetlists-create",
        route: "/asset-lists/:policyId",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={EditPolicy}
            page_name={"EditPolicy"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        name: "Asset Lists Version - Wizard",
        key: "assetlists-version-wizard",
        route: "/asset-lists/:policyId/:version",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={CreatePolicyDocument}
            page_name={"CreatePolicyDocument"}
            isCheckCompanyOrganization={true}
          />
        ),
        icon: <Icon fontSize="medium">create</Icon>,
        noCollapse: true,
        private: true,
      },
      {
        name: "Asset List Review",
        key: "assetlists-review",
        route: "/asset-lists/:policyId/review/:policyReviewId",
        component: (
          <AuthHoc
            checkAuth={true}
            isRedirect={true}
            Component={ReviewPolicyLanding}
            page_name={"Policy Review"}
          />
        ),
      },
    ],
  },
  {
    name: "Billing - Success",
    key: "BillingSuccess",
    route: "/billing/success",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={SignUpPickAPlan}
        page_name={"SignUpPickAPlan"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Billing",
    key: "BillingCancel",
    route: "/billing/cancel",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={SignUpPickAPlan}
        page_name={"SignUpPickAPlan"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  /**Assessment Routes */
  {
    name: "Assessment Types",
    key: "assessment-types",
    route: "/assessments/config/assessment-types",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AssessmentTypes}
        page_name={"AssessmentTypes"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Assessment Scorings",
    key: "assessment-scoring",
    route: "/assessments/config/assessment-scoring",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AssessmentScoring}
        page_name={"AssessmentScoring"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Assessment Item Scoring",
    key: "assessment-item-scoring",
    route: "/assessments/config/assessment-item-scoring",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AssessmentItemScoring}
        page_name={"AssessmentItemScoring"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Take an Assessment",
    key: "assessment-event-take",
    route: "/assessments/events/:assessment_event_id/:version_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={TakeTheAssessment}
        page_name={"TakeTheAssessment"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "View Assessment Versions",
    key: "assessment-event-versions",
    route: "/assessments/events/:assessment_template_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AssessmentEventVersions}
        page_name={"AssessmentEventVersions"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Assessment SSP",
    key: "assessment-cca-new",
    route: "/assessments/CCA/new",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={CreateNewSsp}
        page_name={"CreateNewSsp"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  {
    name: "Assessment CCA View",
    key: "assessment-cca-view",
    route: "/assessments/CCA/:parent_ssp_id/:id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ViewSsp}
        page_name={"ViewSsp"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  {
    name: "Assessment CCA View",
    key: "assessment-cca-view",
    route: "/assessments/CCA/:id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ViewSspVersions}
        page_name={"ViewSspVersions"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  //auditor reports
  {
    name: "Auditor Report",
    key: "assessment-auditor-reports-new",
    route: "/auditor-reports/new",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={CreateNewAuditorReport}
        page_name={"CreateNewAuditorReport"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  {
    name: "Auditor Reports View",
    key: "auditor-reports-view",
    route: "/auditor-reports/:id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ViewAuditorReport}
        page_name={"ViewAuditorReport"}
      />
    ),
  },

  {
    name: "Accept Auditor Invite",
    key: "accept-invite",
    route: "/accept-invite/auditor/:invite_token",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AcceptAuditorInvite}
        page_name={"AcceptAuditorInvite"}
      />
    ),
  },
  {
    name: "Clients",
    key: "asset-sync-cybercns",
    route: "/asset-management/connectsecure",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={CyberCNSAssetSyncSection}
        page_name={"CyberCNSAssetSyncSection"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Clients",
    key: "asset-sync-cybercns",
    route: "/asset-management/connectsecure/v4",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConnectSecurev4AssetSyncSection}
        page_name={"ConnectSecurev4AssetSyncSection"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  {
    name: "Clients",
    key: "asset-sync-nodeware",
    route: "/asset-management/nodeware",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={NodewareAssetSyncSection}
        page_name={"NodewareAssetSyncSection"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  {
    name: "Clients",
    key: "asset-sync-lionguard",
    route: "/asset-management/liongard",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={LionguardAssetSyncSection}
        page_name={"LionguardAssetSyncSection"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Asset Sync Ninja",
    key: "asset-sync-ninja",
    route: "/asset-management/ninja",
    component: (
      <Navigate to="/asset-management/ninja/hardware" replace={true} />
    ),
  },
  {
    name: "Asset Sync Ninja Hardware",
    key: "asset-sync-ninja",
    route: "/asset-management/ninja/hardware",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={NinjaHardwareAssetSyncSection}
        page_name={"NinjaHardwareAssetSyncSection"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Asset Sync Ninja Software",
    key: "asset-sync-ninja-software",
    route: "/asset-management/ninja/software",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={NinjaSoftwareAssetSyncSection}
        page_name={"NinjaSoftwareAssetSyncSection"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Clients",
    key: "asset-sync-ncentral",
    route: "/asset-management/ncentral",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={NCentralAssetSyncSection}
        page_name={"NCentralAssetSyncSection"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Clients",
    key: "asset-sync-ms-guard-hardware",
    route: "/asset-management/msgraph/hardware",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MSGraphAssetSyncSectionHardware}
        page_name={"MSGraphAssetSyncSectionHardware"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  // {
  //   name: "Clients",
  //   key: "asset-sync-ms-guard-data",
  //   route: "/asset-management/msgraph/data",
  //   component: (
  //     <AuthHoc
  //       checkAuth={true}
  //       isRedirect={true}
  //       Component={MSGraphAssetSyncSectionData}
  //       page_name={"MSGraphAssetSyncSectionData"}
  //       isCheckCompanyOrganization={true}
  //     />
  //   ),
  // },
  {
    name: "Clients",
    key: "asset-sync-ms-guard-people",
    route: "/asset-management/msgraph/people",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MSGraphAssetSyncSectionPeople}
        page_name={"MSGraphAssetSyncSectionPeople"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  {
    name: "Assets",
    key: "assets",
    route: "/assets/create-list/:assetType",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AssetManagement}
        page_name={"AssetManagement"}
        isCheckCompanyOrganization={true}
      />
    ),
    noCollapse: true,
    private: true,
    mustBeCompanyAdminOrCompanyUser: true,
    // hide: true,
  },
  {
    name: "Assets",
    key: "assets",
    route: "/assets/create-list",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={AssetManagement}
        page_name={"AssetManagement"}
        isCheckCompanyOrganization={true}
      />
    ),
    noCollapse: true,
    private: true,
    mustBeCompanyAdminOrCompanyUser: true,
  },

  {
    name: "Generate WISP",
    key: "wisp-new",
    route: "/wisp/new",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={CreateNewWisp}
        page_name={"CreateNewWisp"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  {
    name: "View WISP",
    key: "view-wisp",
    route: "/wisp/:parent_wisp_id/:id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ViewWisp}
        page_name={"ViewWisp"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  {
    name: "View WISP Versions",
    key: "view-wisp-versions",
    route: "/wisp/:id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ViewWispVersions}
        page_name={"ViewWispVersions"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  /**adoption campaigns */
  {
    name: "Generate Adoption Campaign",
    key: "adoption-campaign-new",
    route: "/adoption-campaigns/new",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={CreateNewAdoptionCampaign}
        page_name={"CreateNewAdoptionCampaign"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  /**adoption campaigns */
  {
    name: "View Adoption Campaign",
    key: "view-adoption-campaign",
    route: "/adoption-campaigns/:campaign_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ViewAdoptionCampaign}
        page_name={"ViewAdoptionCampaign"}
        isCheckCompanyOrganization={true}
      />
    ),
  },

  /**Settings routes */
  {
    name: "Connections",
    key: "connections",
    route: "/connections/connectwise",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={SaveConnectWiseApiKeys}
        page_name={"saveconnectwiseapikeys"}
        isCheckCompanyOrganization={true}
      />
    ),
    mustBeCompanyAdminOrCompanyUser: true,
  },
  {
    name: "API Keys Config",
    key: "api-keys",
    route: "/connections/api-keys",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ApiKeysConfig}
        page_name={"ApiKeysConfig"}
        isCheckCompanyOrganization={true}
      />
    ),
    mustBeCompanyAdminOrCompanyUser: true,
  },

  {
    name: "Connections",
    key: "connections",
    route: "/connections/symbol",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigSymbol}
        page_name={"ConfigSymbol"}
        isCheckCompanyOrganization={true}
      />
    ),
    mustBeCompanyAdminOrCompanyUser: true,
  },

  {
    name: "Connections",
    key: "connections",
    route: "/connections/huntress",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigHuntress}
        page_name={"ConfigHuntress"}
        isCheckCompanyOrganization={true}
      />
    ),
    mustBeCompanyAdminOrCompanyUser: true,
  },
  /*ms graph settings */
  {
    name: "MS Graph Clients",
    key: "clients-msgraph",
    route: "/connections/msgraph/clients-to-configure",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ClientsToConfigureMsGraphEmailFor}
        page_name={"ClientsToConfigureMsGraphEmailFor"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "MS Graph Assets Clients",
    key: "clients-assets-msgraph",
    route: "/assets/connections/msgraph-assets/clients-to-configure",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ClientsToConfigureMsGraphAssetsFor}
        page_name={"ClientsToConfigureMsGraphAssetsFor"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "API Keys Config MS Graph Email",
    key: "api-keys-email",
    route: "/settings/ms-graph-email-connect/:client_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MsGraphEmailConnect}
        page_name={"MsGraphEmailConnect"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "API Keys Config MS Graph OneDrive",
    key: "api-keys-onedrive",
    route: "/evidence-locker/ms-graph-onedrive-connect/:client_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MsGraphOneDriveConnect}
        page_name={"MsGraphOneDriveConnect"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "API Keys Config MS Graph Sharepoint",
    key: "api-keys-sharepoint",
    route: "/evidence-locker/ms-graph-sharepoint-connect/:client_id/:site_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MsGraphSharePointConnect}
        page_name={"MsGraphSharePointConnect"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "API Keys Config MS Graph GCC High Sharepoint",
    key: "api-keys-sharepoint-gcc",
    route:
      "/evidence-locker/ms-graph-sharepoint-gcc-connect/:client_id/:site_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MsGraphSharePointGccHighConnect}
        page_name={"MsGraphSharePointGccHighConnect"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "SharePoint",
    key: "sharepoint",
    route: "/evidence-locker/sharepoint",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigSharePointSiteId}
        page_name={"ConfigSharePointSiteId"}
        isCheckCompanyOrganization={true}
      />
    ),
    mustBeCompanyAdminOrCompanyUser: true,
  },
  {
    name: "GCC High SharePoint",
    key: "sharepoint-gcc",
    route: "/evidence-locker/sharepoint-gcc",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigSharePointSiteGccId}
        page_name={"ConfigSharePointSiteGccId"}
        isCheckCompanyOrganization={true}
      />
    ),
    mustBeCompanyAdminOrCompanyUser: true,
  },
  {
    name: "API Keys Config MS Team",
    key: "api-keys-ms-teams",
    route:
      "/notification-center/ms-teams-connect/:client_id/:team_id/:channel_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MSTeamsConnect}
        page_name={"MSTeamsConnect"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "API Keys Config Slack",
    key: "api-keys-slack",
    route: "/notification-center/slack-connect/:client_id/:channel_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={SlackConnect}
        page_name={"SlackConnect"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "API Keys Config MS Graph Assets",
    key: "api-keys-assets",
    route: "/settings/ms-graph-assets-connect/:client_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MsGraphAssetsConnect}
        page_name={"MsGraphAssetsConnect"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  /**asset management settings */
  {
    name: "Nodeware Config",
    key: "config-nodeware",
    route: "/assets/connections/nodeware",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigNodeware}
        page_name={"ConfigNodeware"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "ConnectSecure Config",
    key: "config-connectsecure",
    route: "/assets/connections/connectsecure",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigConnectSecure}
        page_name={"ConfigConnectSecure"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "ConnectSecurev4 Config",
    key: "config-connectsecurev4",
    route: "/assets/connections/connectsecure/v4",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigConnectSecurev4}
        page_name={"ConfigConnectSecurev4"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Lionguard Config",
    key: "config-lionguard",
    route: "/assets/connections/liongard",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigLionGuard}
        page_name={"ConfigLionGuard"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Lionguard Config",
    key: "config-lionguard",
    route: "/assets/connections/ninja",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigNinja}
        page_name={"ConfigNinja"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "NCentral Config",
    key: "config-ncentra",
    route: "/assets/connections/ncentral",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={ConfigNCentral}
        page_name={"ConfigNCentral"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  /**Super admin routes */
  {
    name: "MSP Clients",
    key: "mspclients",
    route: "/msp/clients/:company_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={MSPClients}
        page_name={"MSPClients"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Super Admin Password Validation",
    key: "spv",
    route: "/confirm-password",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={SuperAdminPasswordValidation}
        page_name={"SuperAdminPasswordValidation"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Edit Assessment Templates",
    key: "assessment-templates-edit",
    route: "/assessments/templates/:template_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={EditAssessmentTemplates}
        page_name={"EditAssessmentTemplates"}
        isCheckCompanyOrganization={true}
      />
    ),
  },
  {
    name: "Peer Group",
    key: "peer-group-post",
    route: "/peer-group/:post_id",
    component: (
      <AuthHoc
        checkAuth={true}
        isRedirect={true}
        Component={PeerGroupPostsDetails}
        page_name={"Peer Group Post"}
        isCheckCompanyOrganization={true}
      />
    ),
    icon: <Icon fontSize="medium">create</Icon>,
    noCollapse: true,
    private: true,
  },
  {
    name: "Password Validation",
    key: "spv",
    route: "/validate-password/:company_id/:client_id",
    component: <PublicLinkPasswordValidation />,
  },
];
export { publicRoutes, topNavRoutes, routes };
