import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Icon,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import DashboardLayout from "views/containers/DashboardLayout";

function TaskListReports() {
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    // Sample data - replace with actual API call
    const sampleReports = [
      {
        id: 1,
        title: "Tasks Due This Week",
        count: 12,
        icon: "event",
      },
      {
        id: 2, 
        title: "Completed Tasks",
        count: 45,
        icon: "check_circle",
      },
      {
        id: 3,
        title: "High Priority Tasks",
        count: 8,
        icon: "priority_high",
      }
    ];

    setReports(sampleReports);
  }, []);

  if (isLoading) {
    return (
      <MDBox display="flex" justifyContent="center">
        <LoadingSpinner />
      </MDBox>
    );
  }

  return (
    <DashboardLayout>
      <DashboardBreadcrumbs />
      <MDBox p={3}>
        <MDTypography variant="h4" mb={3}>
          Projects Reports
        </MDTypography>
        <Grid container spacing={3}>
          {reports.map((report) => (
            <Grid item xs={12} md={4} key={report.id}>
              <Card>
                <MDBox
                  p={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MDBox>
                    <MDTypography variant="h6" fontWeight="medium">
                      {report.title}
                    </MDTypography>
                    <MDTypography variant="h4" py={2}>
                      {report.count}
                    </MDTypography>
                  </MDBox>

                  <MDBox>
                    <Tooltip title={report.title}>
                      <Icon sx={{ fontSize: "3rem", opacity: 0.5 }}>
                        {report.icon}
                      </Icon>
                    </Tooltip>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default TaskListReports;
