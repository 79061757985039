import React from "react";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon"; // For adding an info icon
import Tooltip from "@mui/material/Tooltip"; // For adding a tooltip

const FeedbackComponent = () => {
  const websiteUrl = "https://support.compliancescorecard.com"; // Website URL

  // Function to handle the click and redirect to the website
  const handleEmailClick = () => {
    window.location.href = websiteUrl; // Redirect to the website
  };

  return (
    <MDBox
      display="flex"
      alignItems="center"
      bgcolor="info.light" // Light background by default
      borderRadius="md"
      p={2}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "info.main", // Darker background on hover
          "& .MuiTypography-root, & .MuiIcon-root": {
            color: "white", // White text and icon on hover
          },
        },
      }}
      onClick={handleEmailClick}
    >
      <Icon sx={{ color: "text.primary", mr: 1 }}>info</Icon>{" "}
      {/* Dark icon by default */}
      <MDTypography variant="h6" color="text.primary">
        Please submit feedback to{" "}
        <span
          style={{
            textDecoration: "underline",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          support.compliancescorecard.com
        </span>
      </MDTypography>
    </MDBox>
  );
};

export default FeedbackComponent;
