import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import {
  createTaskList,
  updateTaskList,
} from "features/company/taskListActions";
import * as Yup from "yup";
import { Formik, Form } from "formik";

const TaskListFormSchema = Yup.object().shape({
  txtTitle: Yup.string().required("The Name is required."),
  txtNotes: Yup.string(),
  dueDate: Yup.date().nullable(),
});

const CreateTaskListDialog = ({
  open,
  onClose,
  onSubmit,
  objUser,
  taskList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmitForm = async (values) => {
    const objData = {
      title: values.txtTitle,
      notes: values.txtNotes,
      due_date: values.dueDate,
    };

    setIsLoading(true);
    dispatch(createTaskList({ objUser, objData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201 || response.status === 200) {
          onSubmit(response.data);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Create Project</DialogTitle>
      <Formik
        initialValues={{
          txtTitle: "",
          txtNotes: "",
          dueDate: null,
        }}
        validationSchema={TaskListFormSchema}
        onSubmit={onSubmitForm}
      >
        {({ values, errors, touched }) => (
          <Form id="TaskListForm" autoComplete="off">
            <DialogContent>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="text"
                  label="Title"
                  name="txtTitle"
                  value={values.txtTitle}
                  placeholder="Project Title"
                  error={errors.txtTitle && touched.txtTitle}
                  success={values.txtTitle.length > 0 && !errors.txtTitle}
                />
              </MDBox>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="textarea"
                  label="Notes"
                  name="txtNotes"
                  value={values.txtNotes}
                  placeholder="Notes"
                  error={errors.txtNotes && touched.txtNotes}
                  success={values.txtNotes?.length > 0 && !errors.txtNotes}
                />
              </MDBox>
              <MDBox mb={0} mt={0}>
                <FormField
                  type="date"
                  label="Due Date"
                  name="dueDate"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.dueDate}
                  error={errors.dueDate && touched.dueDate}
                  success={values.dueDate && !errors.dueDate}
                />
              </MDBox>
            </DialogContent>
            <DialogActions>
              {!isLoading ? (
                <>
                  <MDButton
                    type="submit"
                    color="success"
                    sx={{ padding: "12px 12px" }}
                  >
                    Create
                  </MDButton>
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="dark"
                    sx={{ padding: "12px 12px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </MDButton>
                </>
              ) : (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              )}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CreateTaskListDialog;
