import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { showMsg } from "utils/general";
import { Checkbox } from "@mui/material";
import TermsandConditions from "../SignUpSelectOrganizationType/components/TermsandConditions";

function AgreeToPolygonPlusTermsDialog({
  open,
  onClose,
  stripeCheckoutURL,
  packageToSubscribe,
}) {
  const [isTosAccepted, setIsTosAccepted] = useState(false);
  const [isOneYearCommittmentAccepted, setIsOneYearCommittmentAccepted] =
    useState(false);
  const [termsAndConditionsDisplay, setTermsAndConditionsDisplay] =
    useState("none");

  const proceedToStripeCheckout = () => {
    if (isTosAccepted) {
      if (packageToSubscribe == "lite") {
        window.location.href = stripeCheckoutURL;
        onClose();
      } else if (packageToSubscribe == "plus" && isOneYearCommittmentAccepted) {
        window.location.href = stripeCheckoutURL;
        onClose();
      } else {
        showMsg("error", "Please agree to all terms and conditions to proceed");
      }
    } else {
      showMsg("error", "Please agree to all terms and conditions to proceed");
    }
  };

  const handleTermsAndConditionsToggle = () => {
    if (termsAndConditionsDisplay == "none") {
      setTermsAndConditionsDisplay("block");
    } else {
      setTermsAndConditionsDisplay("none");
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Agree To Subscription Terms</DialogTitle>
      <DialogContent>
        <Checkbox
          checked={isTosAccepted}
          onChange={(event) => {
            setIsTosAccepted(event.target.checked);
          }}
        />

        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{
            cursor: "pointer",
            userSelect: "none",
            ml: -1,
          }}
        >
          &nbsp;&nbsp;I accept the{" "}
          <a href="https://trust.complaincescorecard.com" target="_blank">
            terms and conditions
          </a>
          &nbsp;
        </MDTypography>

        <MDTypography
          sx={{
            display: `${termsAndConditionsDisplay}`,
            margin: "0 !important",
          }}
        >
          <TermsandConditions />
        </MDTypography>
        <br />
        {/* 1 yr committment */}
        {packageToSubscribe == "plus" ? (
          <>
            <Checkbox
              checked={isOneYearCommittmentAccepted}
              onChange={(event) => {
                setIsOneYearCommittmentAccepted(event.target.checked);
              }}
            />

            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{
                cursor: "pointer",
                userSelect: "none",
                ml: -1,
              }}
            >
              &nbsp;&nbsp;I accept the{" "}
              <b>1 Year Minimum Compliance Scorecard Plus Commitment</b>
              &nbsp;
            </MDTypography>
          </>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton
          onClick={proceedToStripeCheckout}
          // variant="gradient"
          color="success"
          sx={{ padding: "12px 12px" }}
        >
          Proceed
        </MDButton>
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default AgreeToPolygonPlusTermsDialog;
